<template>
  <div v-if="!$store.state.mobile" style="height: 100vh;">
    <HeaderComponent></HeaderComponent>
    <div class="grey mt-3" style="height: 500px;">
      <v-carousel cycle hide-delimiters interval="10000" show-arrows="hover" style="box-shadow: 2px 2px 6px black">
        <v-carousel-item
            :src="require('@/assets/Carousel1.jpg')"
            cover
        ></v-carousel-item>


        <v-carousel-item
            :src="require('@/assets/Carousel2.jpg')"
            cover
        ></v-carousel-item>

        <v-carousel-item
            :src="require('@/assets/Carousel3.jpg')"
            cover
        ></v-carousel-item>

        <!--        <v-carousel-item
                    :src="require('@/assets/Carousel4.jpg')"
                    cover
                ></v-carousel-item>-->


      </v-carousel>
    </div>
    <div class="white pt-5" style="height: 700px;">
      <h3 class="text-center mt-10" style="">
        Wir als Bürgergemeinschaft Bad Sassendorf setzen uns aktiv in der Kommunalpolitik ein, <br> um die Interessen
        unserer Bürger auf lokaler Ebene zu vertreten. Dabei konzentrieren wir uns auf folgende Kernbereiche
      </h3>
      <v-row class="mx-0 justify-center mt-n10 align-center"
             style="width: 100%; height: 100% ; padding-left: 20%; padding-right: 20%">
        <v-col class="my-n10 d-flex justify-center" cols="4">
          <v-card
              :rounded="false"

              class="mx-auto hoverCard my-8"
              elevation="16"
              height="200"
              max-width="344"
              style="color: white;   background-image: linear-gradient(to top, #000099 10%, #0909cd 70%);"
          >
            <v-card-item>
              <v-card-title class="d-flex">
                <div class="mr-2" style="height:30px ;width: 12px; background-color: #ffffff">
                </div>
                Bürgerbeteiligung
              </v-card-title>
            </v-card-item>
            <v-card-text class="">
              Wir fördern die aktive Teilnahme der Bürger an politischen Entscheidungen. Durch öffentliche
              Veranstaltungen und Diskussionen stärken wir den Dialog zwischen Bürgern und Gemeindevertretern.
            </v-card-text>
          </v-card>
        </v-col>

        <v-col class="my-n10 d-flex justify-center" cols="4">
          <v-card
              :rounded="false"
              class="mx-auto hoverCard my-8"
              elevation="16"

              height="200"
              max-width="344"
              style="color: white;   background-image: linear-gradient(to top, #000099 10%, #0909cd 70%);"
          >
            <v-card-item>
              <v-card-title class="d-flex">
                <div class="mr-2" style="height:30px ;width: 12px; background-color: #ffffff">
                </div>
                Netzwerkarbeit
              </v-card-title>
            </v-card-item>
            <v-card-text>
              Wir kooperieren mit anderen lokalen Organisationen, Vereinen und politischen Gruppen, um gemeinsame Ziele
              zu erreichen und die politische Landschaft in Bad Sassendorf positiv zu beeinflussen.
            </v-card-text>
          </v-card>
        </v-col>
        <v-col class="my-n16 d-flex justify-center" cols="4">
          <v-card
              :rounded="false"
              class="mx-auto hoverCard my-8"
              elevation="16"
              height="200"
              max-width="344"
              style="color: white;   background-image: linear-gradient(to top, #000099 10%, #0909cd 70%);"
          >
            <v-card-item>
              <v-card-title class="d-flex">
                <div class="mr-2" style="height:30px ;width: 12px; background-color: white">
                </div>
                Anträge und Initiativen
              </v-card-title>
            </v-card-item>
            <v-card-text>
              Wir bringen Anträge und Initiativen im Gemeinderat ein, die sich an den Bedürfnissen und Interessen der
              Bürger orientieren. Unsere Themen reichen von Infrastruktur und Umwelt bis hin zu Bildung und sozialen
              Dienstleistungen.
            </v-card-text>
          </v-card>
        </v-col>

        <v-col class="mt-n16" cols="12">
          <p class="text-center mt-n16">
            Unsere politische Arbeit zielt darauf ab, die Lebensqualität in Bad Sassendorf zu verbessern, die
            Transparenz und <br> Bürgernähe
            in der Gemeindeverwaltung zu fördern und sicherzustellen, dass die Stimme der Bürger in politischen
            Entscheidungen Gehör findet. </p>
        </v-col>
      </v-row>
    </div>
    <FooterComponent></FooterComponent>

  </div>

  <div v-if="$store.state.mobile" style="height: 100vh;">
    <v-layout>

      <v-navigation-drawer
          v-model="$store.state.drawer"
          location="right"
          style="background-image: linear-gradient(to top, #000099 10%, #0909cd 70%);color: white"
          temporary
      >
        <DrawerComponent></DrawerComponent>
      </v-navigation-drawer>
      <v-main style="height: 100%">
        <HeaderComponent></HeaderComponent>

        <div class="grey px-3 mt-3" style="height: 500px;">
          <v-carousel cycle hide-delimiters interval="10000" show-arrows="true" style="box-shadow: 2px 2px 6px black">
            <v-carousel-item
                :src="require('@/assets/Carousel1.jpg')"
                cover
            ></v-carousel-item>


            <v-carousel-item
                :src="require('@/assets/Carousel2.jpg')"
                cover
            ></v-carousel-item>

            <v-carousel-item
                :src="require('@/assets/Carousel3.jpg')"
                cover
            ></v-carousel-item>
          </v-carousel>
        </div>
        <div class="px-4 white" style="height: 1200px;">
          <h4 class="text-center mt-10" style="">
            Wir als Bürgergemeinschaft Bad Sassendorf setzen uns aktiv in der Kommunalpolitik ein, <br> um die
            Interessen
            unserer Bürger auf lokaler Ebene zu vertreten. Dabei konzentrieren wir uns auf folgende Kernbereiche
          </h4>
          <v-row class="mx-0 justify-center align-center"
                 style="width: 100%; ">
            <v-col class="mb-n8 d-flex justify-center" cols="12">
              <v-card
                  :rounded="false"

                  class="mx-auto hoverCard my-8"
                  elevation="16"
                  height="200"
                  max-width="344"
                  style="color: white;   background-image: linear-gradient(to top, #000099 10%, #0909cd 70%);"
              >
                <v-card-item>
                  <v-card-title class="d-flex">
                    <div class="mr-2" style="height:30px ;width: 12px; background-color: #ffffff">
                    </div>
                    Bürgerbeteiligung
                  </v-card-title>
                </v-card-item>
                <v-card-text>
                  Wir fördern die aktive Teilnahme der Bürger an politischen Entscheidungen. Durch öffentliche
                  Veranstaltungen und Diskussionen stärken wir den Dialog zwischen Bürgern und Gemeindevertretern.
                </v-card-text>
              </v-card>
            </v-col>
            <v-col class="my-n8 d-flex justify-center" cols="12">
              <v-card
                  :rounded="false"
                  class="mx-auto hoverCard my-8"
                  elevation="16"

                  height="200"
                  max-width="344"
                  style="color: white;   background-image: linear-gradient(to top, #000099 10%, #0909cd 70%);"
              >
                <v-card-item>
                  <v-card-title class="d-flex">
                    <div class="mr-2" style="height:30px ;width: 12px; background-color: #ffffff">
                    </div>
                    Netzwerkarbeit
                  </v-card-title>
                </v-card-item>
                <v-card-text>
                  Wir kooperieren mit anderen lokalen Organisationen, Vereinen und politischen Gruppen, um gemeinsame
                  Ziele
                  zu erreichen und die politische Landschaft in Bad Sassendorf positiv zu beeinflussen.
                </v-card-text>
              </v-card>
            </v-col>
            <v-col class="my-n8 d-flex justify-center" cols="12">
              <v-card
                  :rounded="false"
                  class="mx-auto hoverCard my-8"
                  elevation="16"
                  height="200"
                  max-width="344"
                  style="color: white;   background-image: linear-gradient(to top, #000099 10%, #0909cd 70%);"
              >
                <v-card-item>
                  <v-card-title class="d-flex">
                    <div class="mr-2" style="height:30px ;width: 12px; background-color: white">
                    </div>
                    Anträge und Initiativen
                  </v-card-title>
                </v-card-item>
                <v-card-text>
                  Wir bringen Anträge und Initiativen im Gemeinderat ein, die sich an den Bedürfnissen und Interessen
                  der
                  Bürger orientieren. Unsere Themen reichen von Infrastruktur und Umwelt bis hin zu Bildung und sozialen
                  Dienstleistungen.
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12">
              <p class="text-center ">
                Unsere politische Arbeit zielt darauf ab, die Lebensqualität in Bad Sassendorf zu verbessern, die
                Transparenz und <br> Bürgernähe
                in der Gemeindeverwaltung zu fördern und sicherzustellen, dass die Stimme der Bürger in politischen
                Entscheidungen Gehör findet. </p>
            </v-col>
          </v-row>
        </div>
        <FooterComponent></FooterComponent>
      </v-main>
    </v-layout>


  </div>
</template>

<script>
import HeaderComponent from "@/components/HeaderComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import DrawerComponent from "@/components/DrawerComponent.vue";


export default {
  name: 'HomeView',

  components: {
    DrawerComponent,
    FooterComponent,
    HeaderComponent
  },
};
</script>
<style scoped>
.hoverCard {
  transition: box-shadow 0.3s ease;
  box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.72) !important;
}

.hoverCard:hover {
  box-shadow: 6px 6px 8px rgba(0, 0, 0, 0.8) !important; /* Erweitern des Schattens statt Skalierung */
}

.banner {
  background-color: rgba(1, 0, 154, 0.69);
  height: 150px;
  width: 380px;
  position: absolute;
  top: 50%;
  left: 17%;
  box-shadow: 3px 3px 6px black;
  backdrop-filter: blur(4px);
  border-left: rgba(255, 255, 255, 0.58) 1px solid;
  border-top: rgba(255, 255, 255, 0.58) 1px solid;
  padding: 20px;
  border-radius: 5px;
}
</style>
