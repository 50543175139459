<template>
  <div v-if="!$store.state.mobile" class="footer">
    <v-row class="justify-center mx-0" style="width: 100%">
      <v-col cols="6">
        <h2 class="text-white">UNSERE POLITIK FÜR BAD-SASSENDORF</h2>
        <p class="text-white mt-" style="font-size: 14px">bürgernah | kompetent</p>
        <br>
        <p class="text-white mt-" style="font-size: 12px">BG-Bürgerbüro:</p>
        <p class="text-white mt-" style="font-size: 12px">Zum Leinweber 22</p>
        <p class="text-white mt-" style="font-size: 12px">59505 Bad Sassendorf</p> <br>
        <p class="text-white mt-" style="font-size: 12px">Kontakt:</p>
        <p class="text-white mt-" style="font-size: 12px">info@buergergemeinschaft-bad-sassendorf.de</p>

      </v-col>
      <v-col class="d-flex justify-center" cols="2">
        <v-row class="mx-0" style="width: 100%;">
          <v-col class="d-flex justify-end" cols="6">
            <a href="https://www.facebook.com/BGBadSassendorf/" target="_blank">
              <Icon icon="entypo-social:facebook" style="font-size: 40px; color: white;"/>
            </a>
          </v-col>
          <v-col class="d-flex justify-start" cols="6">
            <a href="https://www.instagram.com/bg_bad_sassendorf/" target="_blank">
              <Icon icon="skill-icons:instagram" style="font-size: 40px; color: white;"/>
            </a>
          </v-col>

        </v-row>
      </v-col>
    </v-row>
    <div style="position: relative; left: 70%;">
      <router-link aria-label="Impressum" class="nav-link" title="Impressum" to="/impressum">
        <span class="text-white">Impressum</span>
      </router-link> &nbsp;
      <span class="text-white">|</span> &nbsp;
      <router-link aria-label="Impressum" class="nav-link" title="Impressum" to="/admin">
        <span class="text-white">Verwaltung</span>
      </router-link> &nbsp; &nbsp;
      <span class="text-white">|</span> &nbsp;
      <router-link aria-label="Impressum" class="nav-link" title="Impressum" to="/datenschutz">
        <span class="text-white">Datenschutz</span>
      </router-link> &nbsp;
    </div>
  </div>

  <div v-if="$store.state.mobile" class="footer">
    <v-row class="justify-center mx-0" style="width: 100%">
      <v-col cols="12">
        <h4 class="text-white">UNSERE POLITIK FÜR BAD-SASSENDORF</h4>
        <p class="text-white mt-" style="font-size: 12px">bürgernah | kompetent</p>
        <br>
        <p class="text-white mt-" style="font-size: 11px">BG-Bürgerbüro:</p>
        <p class="text-white mt-" style="font-size: 11px">Zum Leinweber 22</p>
        <p class="text-white mt-" style="font-size: 11px">59505 Bad Sassendorf</p> <br>
        <p class="text-white mt-" style="font-size: 11px">Kontakt:</p>
        <p class="text-white mt-" style="font-size: 11px">info@buergergemeinschaft-bad-sassendorf.de</p>

      </v-col>
      <v-col cols="12">
        <div style="">
          <router-link aria-label="Impressum" class="nav-link" style="font-size: 12px" title="Impressum"
                       to="/impressum">
            <span class="text-white">Impressum</span>
          </router-link> &nbsp;
          <span class="text-white">|</span> &nbsp;
          <router-link aria-label="Impressum" class="nav-link" style="font-size: 12px" title="Impressum" to="/admin">
            <span class="text-white">Verwaltung</span>
          </router-link> &nbsp; &nbsp;
          <span class="text-white">|</span> &nbsp;
          <router-link aria-label="Impressum" class="nav-link" style="font-size: 12px" title="Impressum"
                       to="/datenschutz">
            <span class="text-white">Datenschutz</span>
          </router-link> &nbsp;
        </div>
      </v-col>

    </v-row>

  </div>
</template>

<script>
import {Icon} from '@iconify/vue';

export default {
  name: "FooterComponent",
  components: {
    Icon
  }
}
</script>

<style scoped>
.footer {
  width: 100%;
  height: 250px;
  background-image: linear-gradient(to right, #000099 10%, #0909cd 30%, #000099 100%);
}

.nav-link {
  text-decoration: none;
  color: inherit;
}
</style>
