<template>
  <div v-if="!$store.state.mobile">
    <div v-if="!user" class="d-flex justify-center align-center backgroundLogin "
         style="width: 100vw; height: 100vh">
      <LoginComponent/>
    </div>
    <div v-else class=" background" style="position: fixed">
      <v-row class="mx-0 mt-10 justify-center" style="width: 100%">
        <v-col class="d-flex justify-end" cols="2">
          <Icon class="icon" icon="ion:arrow-back"
                style="font-size: 40px; color: aqua;"
                @click="$router.push('/')"/>
        </v-col>
        <v-col cols="4">
          <v-select
              v-model="einstellung"
              :items="einstellungen"
              label="Wählen Sie eine Einstellung"
              style="height: 100%"
              variant="solo"
          />
        </v-col>
        <v-col cols="2">
          <Icon class=" icon" icon="material-symbols:logout"
                style="font-size: 40px; color: aqua;"
                @click="logout"/>
        </v-col>
        <v-col class="d-flex justify-center" cols="10">
          <v-card
              class="pb-5"
              max-width="1000"
              min-height="500"
              style="width: 100%; height: 650px; background-color: rgba(255,255,255,0.21); backdrop-filter: blur(4px); box-shadow: 2px 2px 5px black; border-left: rgba(255,255,255,0.47) 2px solid; border-top: rgba(255,255,255,0.44) 2px solid; overflow-y: scroll">
            <AktuellesComponent v-if="einstellung === 'Aktuelles' "/>
            <VorsitzenderComponent v-if="einstellung === 'Vorsitzender' "/>
            <StellvertretenderVorsitzenderComponent v-if="einstellung === 'Stellvertretender Vorsitzender'"/>
            <FraktionsvorsitzenderComponent v-if="einstellung === 'Fraktionsvorsitzender'"/>
            <StellvertretenderFraktionsvorsitzenderComponent
                v-if="einstellung === 'Stellvertretender Fraktionsvorsitzender'"/>
            <MitgliedImGemeinderatComponent v-if="einstellung === 'Mitglied im Gemeinderat'"/>
            <SchriftfuehrerComponent v-if="einstellung === 'Schriftführer'"/>
            <SchatzmeisterComponent v-if="einstellung === 'Schatzmeister'"/>
          </v-card>
        </v-col>

      </v-row>


      <div class="pa-4 text-center">
        <v-dialog
            v-model="dialog"
            max-width="600"
            persistent
        >
          <v-card
              prepend-icon="mdi-account"
              title="Password ändern"
          >
            <v-form @submit.prevent="setNewPassword">
              <v-card-text>
                <v-row dense>
                  <v-col
                      cols="12"
                  >
                    <v-text-field
                        v-model="password"
                        :append-icon="show ?'mdi-eye':'mdi-eye-off'"
                        :type="show ?'text':'password'"
                        label="Neues Passwort*"
                        required
                        @click:append="show=!show"
                    ></v-text-field>
                  </v-col>

                  <v-col
                      cols="12"
                  >
                    <v-text-field
                        v-model="confirmPassword"
                        :type="show ?'text':'password'"
                        label="Passwort bestätigen*"
                        required></v-text-field>
                  </v-col>
                </v-row>

                <small class="text-caption text-medium-emphasis">*Pflichtfeld</small>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    :loading="loading"
                    color="green darken-1"
                    text="Speichern"
                    type="submit"
                    @click="setNewPassword"
                ></v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </div>
  <div v-if="$store.state.mobile" style="height: 100vh;">
    <v-layout>

      <v-navigation-drawer
          v-model="$store.state.drawer"
          location="right"
          style="background-image: linear-gradient(to top, #000099 10%, #0909cd 70%);color: white"
          temporary
      >
        <DrawerComponent></DrawerComponent>
      </v-navigation-drawer>
      <v-main style="height: 100%">
        <HeaderComponent></HeaderComponent>
        <div class="d-flex justify-center align-center" style="height: 60vh">
          <div
              style="height: 300px; width: 300px; background-image: linear-gradient(to top, #000099 10%, #0909cd 70%);">
            <v-card-title class="text-white text-center mt-10">
              Diese Ansicht ist auf <br> mobilen Geräten nicht <br> verfügbar
            </v-card-title>
          </div>
        </div>
        <FooterComponent></FooterComponent>
      </v-main>
    </v-layout>


  </div>

</template>

<script>

import {mapGetters} from "vuex";
import LoginComponent from "@/components/LoginComponent.vue";
import axios from "axios";
import VorsitzenderComponent from "@/components/VorsitzenderComponent.vue";
import StellvertretenderVorsitzenderComponent from "@/components/StellvertretenderVorsitzenderComponent.vue";
import FraktionsvorsitzenderComponent from "@/components/FraktionsvorsitzenderComponent.vue";
import MitgliedImGemeinderatComponent from "@/components/MitgliedImGemeinderatComponent.vue";
import SchriftfuehrerComponent from "@/components/SchriftfuehrerComponent.vue";
import AktuellesComponent from "@/components/AktuellesComponent.vue";
import {Icon} from "@iconify/vue/dist/iconify";
import StellvertretenderFraktionsvorsitzenderComponent
  from "@/components/StellvertretenderFraktionsvorsitzenderComponent.vue";
import SchatzmeisterComponent from "@/components/SchatzmeisterComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import DrawerComponent from "@/components/DrawerComponent.vue";
import HeaderComponent from "@/components/HeaderComponent.vue";

export default {
  data() {
    return {
      einstellung: 'Aktuelles',
      einstellungen: ['Aktuelles', 'Vorsitzender', 'Stellvertretender Vorsitzender', 'Fraktionsvorsitzender', 'Stellvertretender Fraktionsvorsitzender', 'Mitglied im Gemeinderat', 'Schriftführer', 'Schatzmeister'],
      password: '',
      confirmPassword: '',
      loading: false,
      dialog: false,
      show: false,
    }
  },
  watch: {
    'user.generatedPassword'(newVal) {
      if (newVal) {
        this.dialog = true;
      }
    }
  },
  methods: {
    async getUser() {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('/user', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        this.$store.dispatch('setUser', response.data);
      } catch (error) {
        console.log(error);
      }
    },

    logout() {
      localStorage.removeItem('token');
      this.$store.dispatch('user', false);
      location.reload()
    },

    setNewPassword() {
      this.loading = true
      if (this.password === '' && this.password != null) {
        alert('Passwort darf nicht leer sein');
        this.loading = false
        return;
      }
      if (this.password === this.confirmPassword) {
        axios.post('/password', {
          email: this.user.email,
          password: this.password
        }).then(() => {
          this.dialog = false;
        }).catch((error) => {
          console.log(error);
        });
      } else {
        alert('Passwörter stimmen nicht überein');
      }
      this.loading = false
    }
  },
  mounted() {
    this.getUser();
  },
  components: {
    HeaderComponent, DrawerComponent, FooterComponent,
    VorsitzenderComponent,
    StellvertretenderVorsitzenderComponent,
    FraktionsvorsitzenderComponent,
    StellvertretenderFraktionsvorsitzenderComponent,
    MitgliedImGemeinderatComponent,
    SchriftfuehrerComponent,
    SchatzmeisterComponent,
    AktuellesComponent,
    LoginComponent,
    Icon
  },
  computed: {
    ...mapGetters(['user'])
  },
  created() {
  }
}
</script>

<style scoped>
.background {
  background-image: url("../assets/bg_login_dialog.jpg");
  background-size: cover;
  width: 100vw;
  height: 100vh
}

.icon {
  color: black;
}

.icon:hover {
  color: blue;
  cursor: pointer;
}

.backgroundLogin {
  background-image: url("../assets/bg_login_dialog.jpg");
  background-size: cover;
}

.v-row {
  width: 100%;
  margin-left: 0px;
  margin-right: 0px;
}

::-webkit-scrollbar {
  width: 0;
  background: transparent;
}
</style>
